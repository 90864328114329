import React from 'react'
import { Box, Stack, Text, Button, Flex, Image } from '@chakra-ui/react'
import { FcLock } from 'react-icons/fc'
import HeroImage from '../../Assets/images/ab1.webp'

const About = () => {
  return (
    <Box>
      <Stack p="4" spacing={'40px'} boxShadow="lg" m="4" borderRadius="sm">

      {/* <Stack direction={{ base: 'column', md: 'row' }} justifyContent="space-between">
        <Stack direction={'column'}>
        <Text textAlign={'left'} fontWeight={700} fontSize={'3xl'}>Managed IT Services</Text>
        <hr/>
        <br/>
        <Text fontSize={{ base: 'lg' }} textAlign={'left'} maxW={'4xl'}>
        At CODISE-IT, we specialize in delivering cutting-edge Managed IT Services tailored to meet the unique needs of businesses in today's digital landscape. Our comprehensive solution suite empowers organizations to optimize their technology infrastructure, enhance operational efficiency, and achieve business goals. As a leading provider of Managed IT Services, we understand that technology is the backbone of modern businesses. Our team of experienced IT professionals is dedicated to ensuring that your IT systems are reliable, secure, and aligned with your strategic objectives. We leverage our expertise and industry best practices to provide a seamless IT experience, allowing you to focus on your core competencies.
        </Text>
        </Stack>
        <Stack direction={{ base: 'column', md: 'row' }}>
          <Image width={'400px'} src={HeroImage} alt="Image"/>
        </Stack>
      </Stack> */}
      {/* <Stack direction={{ base: 'column', md: 'row' }} justifyContent="space-between">
        <Stack direction={'column'}>
        <Text textAlign={'left'} fontWeight={700} fontSize={'3xl'}>Managed IT Services</Text>
        <hr/>
        <br/>
        <Text fontSize={{ base: 'lg' }} textAlign={'left'} maxW={'4xl'}>
        At CODISE-IT, we specialize in delivering cutting-edge Managed IT Services tailored to meet the unique needs of businesses in today's digital landscape. Our comprehensive solution suite empowers organizations to optimize their technology infrastructure, enhance operational efficiency, and achieve business goals. As a leading provider of Managed IT Services, we understand that technology is the backbone of modern businesses. Our team of experienced IT professionals is dedicated to ensuring that your IT systems are reliable, secure, and aligned with your strategic objectives. We leverage our expertise and industry best practices to provide a seamless IT experience, allowing you to focus on your core competencies.
        </Text>
        </Stack>
        <Stack direction={{ base: 'column', md: 'row' }}>
          <Image width={'400px'} src={HeroImage} alt="Image"/>
        </Stack>
      </Stack> */}
    </Stack>

    <Stack m="4" mt={'50px'} p={'50px'} pt={'20px'} boxShadow="lg"  borderRadius="sm" textAlign={'justify'}>
    <ol>
            <li>
              <b>Proven Expertise:</b>
              <br/>With years of industry experience, we deeply understand the IT landscape and the challenges businesses face. Our team of certified professionals possesses knowledge across various technologies and industries. We stay updated with the latest trends and emerging technologies to provide innovative and future-proof solutions.</li>
              <br/>
              <li>
              <b>Customized Solutions:</b>
              <br/> We recognize that every business has unique IT requirements. Our Managed IT Services are highly flexible and tailored to your specific needs. By comprehensively assessing your existing infrastructure, we develop customized strategies and solutions that address your pain points and align with your business objectives.</li>
              <br/><li><b>Proactive Support:</b>
              <br/> We believe in taking a proactive approach to IT management. Our proactive monitoring tools and methodologies allow us to detect and resolve potential issues before they impact your operations. We conduct regular system health checks, implement preventive maintenance, and apply security patches and updates to keep your systems running smoothly and securely.</li>
              <br/><li><b>Robust Security Measures:</b>
              <br/> Data breaches and cyber threats pose significant risks to businesses of all sizes. At CODISE-IT, we prioritize the security of your IT infrastructure. We deploy advanced security solutions, including firewalls, intrusion detection systems, encryption, and multi-factor authentication, to safeguard your sensitive data and protect your business from evolving cyber threats.</li>
              <br/><li><b>Scalability and Growth:</b>
              <br/> As your business expands, so do your IT needs. Our Managed IT Services are designed to scale alongside your growth. Whether you need to add new users, upgrade hardware, or implement additional software, we have the expertise to support your evolving technology requirements. Our scalable solutions ensure that your IT infrastructure remains agile and adaptable.</li>
              <br/><li><b>Cost Optimization:</b>
              <br/> We understand the importance of cost-effectiveness for businesses. Our Managed IT Services help you optimize your IT investments. By leveraging our expertise, proactive management, and strategic planning, we help you reduce downtime, increase productivity, and minimize IT-related expenses. We provide transparent pricing models and flexible service packages to suit your budgetary needs.
            </li>
          </ol>
    </Stack>
    </Box>
  )
}

export default About