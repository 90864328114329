import React from 'react'
import {
    Box,
    Flex,
    Heading,
    Text,
    Image,
    Stack,
    Container,
    Avatar,
  IconButton,
  useBreakpointValue,
    useColorModeValue,
  } from '@chakra-ui/react'

// Here we have used react-icons package for the icons
import { BiLeftArrowAlt, BiRightArrowAlt } from 'react-icons/bi'
// And react-slick as our Carousel Lib
import Slider from 'react-slick'

import Teams from "../../Assets/images/Partners/Microsoft-Teams-Symbol.png"
import Office from "../../Assets/images/Partners/Microsoft-Office-365-Logo.png"
import Azure from "../../Assets/images/Partners/Microsoft-Azure-Logo-1.png"
import Windows from "../../Assets/images/Partners/Windiws.jpeg"
import Dell from "../../Assets/images/Partners/Dell.png"
import VMWare from "../../Assets/images/Partners/vmware-png-logo-10.png"
import Lenove from "../../Assets/images/Partners/lenovopro.jpg"
import Sophos from "../../Assets/images/Partners/Sophos.webp"
// Settings for the slider
const settings = {
  dots: true,
  arrows: false,
  fade: true,
  infinite: true,
  autoplay: true,
  speed: 500,
  autoplaySpeed: 5000,
  slidesToShow: 1,
  slidesToScroll: 1,
}


const Partners = () => {
  // As we have used custom buttons, we need a reference variable to
  // change the state
  const [slider, setSlider] = React.useState(null)

  // These are the breakpoints which changes the position of the
  // buttons as the screen size changes
  const top = useBreakpointValue({ base: '90%', md: '50%' })
  const side = useBreakpointValue({ base: '30%', md: '40px' })

  // This list contains all the data for carousels
  // This can be static or loaded from a server
  const cards = [
    {
      id:0,
      images:[ Teams , Sophos, Office,VMWare],
    },
    {
      id:1,
      images:[ Azure , Windows, Lenove ,Dell],
    },
  ]
  return (
    <Box bg={useColorModeValue('gray.100', 'gray.700')}>
    <Container maxW={'7xl'} py={16} as={Stack} spacing={12}>
      <Stack spacing={0} align={'center'}>
        <Heading>Our Partners</Heading>
        <Text>We have been working with people around the globe.</Text>
      </Stack>
      <Stack
        direction={{ base: 'column', md: 'row' }}
        spacing={{ base: 10, md: 4, lg: 10 }}>
            <Box position={'relative'} height={'280px'} width={'full'} overflow={'hidden'}>
      {/* CSS files for react-slick */}
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />
      {/* Left Icon */}
      <IconButton
        aria-label="left-arrow"
        variant="ghost"
        position="absolute"
        left={side}
        top={top}
        transform={'translate(0%, -50%)'}
        zIndex={2}
        onClick={() => slider?.slickPrev()}>
        <BiLeftArrowAlt size="40px" />
      </IconButton>
      {/* Right Icon */}
      <IconButton
        aria-label="right-arrow"
        variant="ghost"
        position="absolute"
        right={side}
        top={top}
        transform={'translate(0%, -50%)'}
        zIndex={2}
        onClick={() => slider?.slickNext()}>
        <BiRightArrowAlt size="40px" />
      </IconButton>
      {/* Slider */}
      <Slider {...settings} ref={(slider) => setSlider(slider)}>
        {cards.map((card, index) => (
          <Box
            key={index}
            height={'6xl'}
            position="relative"
            backgroundPosition="center"
            backgroundRepeat="no-repeat"
            backgroundSize="cover"
            // backgroundImage={`url(${card.image})`}
            >
            {/* This is the block you need to change, to customize the caption */}
            <Container size="container.lg" height="280px" position="relative">
              <Flex
                spacing={6}
                w={'full'}
                maxW={'lg'}
                position="absolute"
                top="50%"
                gap="60px"
                transform="translate(0, -50%)"
                justifyContent={'center'}
                alignItems={'center'}
                >
                {/* <Heading fontSize={{ base: '3xl', md: '4xl', lg: '5xl' }}>
                  {card.title}
                </Heading> */}
                {/* <Text fontSize={{ base: 'md', lg: 'lg' }} color="GrayText">
                  {card.text}
                </Text> */}
                {
                  card.images.map((img,index)=>{
                    return (
                      <Image key={index} src={img} boxSize='50%' fallbackSrc={card.title} />
                    )
                  })
                }
              </Flex>
            </Container>
          </Box>
        ))}
      </Slider>
    </Box>
      </Stack>
    </Container>
  </Box>
  )
}

export default Partners