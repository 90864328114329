import React from 'react'
import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Icon,
  Accordion,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  AccordionButton,
  SimpleGrid,
  Card,
  CardFooter,
  CardBody,
  CardHeader,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import { ReactElement } from 'react'
import { ChevronDownIcon } from '@chakra-ui/icons'
import {
  FcAbout,
  FcAssistant,
  FcCollaboration,
  FcDonate,
  FcManager,
} from 'react-icons/fc'
import BackImage from '../../Assets/images/serviceimage.webp'
import { Link } from 'react-router-dom'

const ServiceCard = ({ heading, description, icon, href }) => {
  return (
    <Box
      maxW={{ base: 'full', md: '296px' }}
      w={'full'}
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      p={2}>
      <Stack align={'start'} justifyContent={'space-between'} height={'100%'}>
        <Box mt={4} textAlign={'start'} width={'100%'}>
        <Accordion defaultIndex={[0]} border={'none'} width="100%">
          <AccordionItem border={'none'} width={'100%'} >
            <AccordionButton
            border={'none'}
              display="flex"
              alignItems="start"
              width={'100%'}
              justifyContent="space-between">
                <Flex width={'100%'} justifyContent={'space-between'} alignItems={'center'}>
              <Heading size="md" display={'flex'} textAlign={'start'}>{heading}</Heading>
              <ChevronDownIcon fontSize="24px"/>
              </Flex>
            </AccordionButton>
            <AccordionPanel pb={4}>
              <hr/>
            <Text mt={1} p={2} fontSize={'sm'}>
              <ul>
                {description.map((item)=>{
                  return(
                    <li>{item}</li>
                  )
                })}
                </ul>
          </Text>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        </Box>
        <Box width={'100%'}>
          <Link to={heading}><Button backgroundColor={'green.600'} w={"100%"}>View More</Button></Link>
        </Box>
      </Stack>
    </Box>
  )
}

const Services = () => {
  const innerBoxStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    boxSize: 'full',
    color: 'white',
    textShadow: '0 0 20px black',
    fontWeight: 'bold',
    fontSize: '20px',
  }
  const serviceData = [
    {
      id:1,
      serviceName:"Network Setup and Management",
      serviceInfo: [
        "Installation, configuration, and maintenance of network infrastructure.",
        "LAN/WAN setup and network management.",
        "Firewall implementation and Security Services.",
        "Network monitoring and troubleshooting."
      ],
      mainPara:[
        ""
      ]
    },
    {
      id:2,
      serviceName:"Help Desk and Technical Support",
      serviceInfo: [
        "Remote and on-site technical assistance.",
        "Troubleshooting hardware and software issues.",
        "User support and training.",
        "Incident management and resolution."
      ]
    },
    {
      id:3,
      serviceName:"Mobile Device Management (MDM)",
      serviceInfo: [
        "Mobile device configuration and provisioning.",
        "Application management and deployment.",
        "Security and policy enforcement.",
        "Remote device tracking and wiping."
      ]
    },
    {
      id:4,
      serviceName:"IT Infrastructure Monitoring",
      serviceInfo: [
        "Proactive monitoring of servers, networks, and systems.",
        "Performance and availability monitoring.",
        "Alerting and notification services.",
        "Capacity planning and optimization."
      ]
    },
    {
      id:5,
      serviceName:"Software Development and Integration",
      serviceInfo: [
          "Custom software development based on client requirements.",
          "Application integration and middleware solutions.",
          "Legacy system modernization and migration.",
          "Quality assurance and testing services."
      ]
    },
    {
      id:6,
      serviceName:"IT Consulting and Project Management",
      serviceInfo: [
         "IT strategy development and planning.",
         "Technology assessment and recommendations.",
         "Project scoping, implementation, and management.",
         "IT budgeting and resource allocation."
      ]
    },
    {
      id:7,
      serviceName:"IT Security and Cybersecurity",
      serviceInfo: [
        "Vulnerability assessments and penetration testing.",
        "Firewall and antivirus setup and management.",
        "Intrusion detection and prevention systems.",
        "Security audits and policy enforcement."
      ]
    },
    {
      id:8,
      serviceName:"Server Administration",
      serviceInfo: [
          "Server setup, configuration, and maintenance.",
          "User management and access control.",
          "Performance monitoring and optimization.",
          "Backup and disaster recovery solutions."
      ]
    },
    {
      id:9,
      serviceName:"Data Backup and Recovery",
      serviceInfo: [
          "Regular data backup solutions.",
          "Data recovery services in case of hardware failure or data loss.",
          "Implementation of backup strategies and disaster recovery plans.",
          "Off-site data storage options."
      ]
    },
    {
      id:10,
      serviceName:"Internet of Things (IoT) Integration",
      serviceInfo:[
        "Designing and implementing IoT solutions for businesses.",
        "Connecting and integrating IoT devices and sensors.",
        "Developing custom applications to analyze and utilize IoT data.",
        "Ensuring security and scalability of IoT infrastructure."
      ]
    }
  ]
  return (
    <Box p={'20px'}>
      <Stack spacing={4} as={Container} maxW={'3xl'} textAlign={'center'}>
        <Heading fontSize={{ base: '2xl', sm: '4xl' }} fontWeight={'bold'}>
          Codise Services
        </Heading>
        <Text color={'gray.600'} fontSize={{ base: 'sm', sm: 'lg' }}>
        CODISE-IT, a business brand that not only fulfill customer's requirement but provide them efficiency, productivity, scalability and security.And here are the list of things we provide.
        </Text>
      </Stack>

      <Box mt={12}>
        <Flex flexWrap="wrap" gridGap={6} justify="center">
          {
            serviceData.map((serviceItem)=>{
              return (
                <ServiceCard
            heading={serviceItem.serviceName}
            icon={<Icon as={FcAssistant} w={10} h={10} />}
            description={serviceItem.serviceInfo}
            
            href={'#'}
            
          />
              )
            })
          }
        </Flex>
      </Box>
      <Box m={'30px'} mt={'40px'}
            maxH={'1md'}
            position="relative"
            backgroundPosition="center"
            backgroundRepeat="no-repeat"
            backgroundSize="cover"
            backgroundImage={BackImage}>
            <Container size="container.lg" height="600px" position="relative">
              <Stack
                spacing={6}
                w={'full'}
                maxW={'lg'}
                position="absolute"
                top="50%"
                transform="translate(0, -50%)">
                {/* <Heading fontSize={{ base: '3xl', md: '4xl', lg: '5xl' }}>
                  Ab
                </Heading>
                <Text fontSize={{ base: 'md', lg: 'lg' }} color="GrayText">
                  AB
                </Text> */}
              </Stack>
            </Container>
          </Box>
    </Box>
  )
}

export default Services