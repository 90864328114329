import React from 'react'
import {
    Box,
    Flex,
    Text,
    IconButton,
    Button,
    Stack,
    Collapse,
    Icon,
    Popover,
    PopoverTrigger,
    PopoverContent,
    useColorModeValue,
    useBreakpointValue,
    Image,
    useDisclosure,
  } from '@chakra-ui/react'
  import { Link } from "react-router-dom";
  import {
    HamburgerIcon,
    CloseIcon,
    ChevronDownIcon,
    ChevronRightIcon,
  } from '@chakra-ui/icons'
import { ColorModeSwitcher } from '../Theme/ColorModeSwitcher'
import Logo from "../../Assets/images/logo.png"




const Navbar = () => {

    const { isOpen, onToggle } = useDisclosure();
    const NavlinkList = [
        {
          label: 'Home',
          to: '/',
        },
        {
          label: 'Services',
          to: '/services',
        },
        {
          label: 'Contact',
          to: '/contact',
        },
        {
          label: 'About',
          to: '/about',
        },
        {
          label: 'Blog',
          to: '/blog',
        },
    ];
  return (
    <Box>
    <Box>
    <Flex
      bg={useColorModeValue('white', 'gray.800')}
      color={useColorModeValue('gray.600', 'white')}
      minH={'60px'}
      py={{ base: 2 }}
      px={{ base: 4 }}
      align={'center'}>
      <Stack
        flex={{ base: 1, md: 0 }}
        justify={'flex-start'}
        direction={'row'}
        spacing={6}
        display={{ base: 'flex', md: 'flex' }}
        >
        <IconButton
          onClick={onToggle}
          icon={isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} display={{ md: 'none' }}/>}
          variant={'ghost'}
          aria-label={'Toggle Navigation'}
        />
      </Stack>
      <Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'center' }}>
      <Box>
        <Link to="/"><Image w="auto" h={"60px"} src={Logo} alt='Logo' /></Link>
        </Box>
      </Flex>

      <Stack
        flex={{ base: 1, md: 0 }}
        justify={'flex-end'}
        direction={'row'}
        spacing={6}>
        <ColorModeSwitcher />
      </Stack>
    </Flex>

    <Collapse in={isOpen} animateOpacity
    display={{ md: 'none' }}
    >
        <Stack bg={useColorModeValue('white', 'gray.800')} p={4} display={{ md: 'none' }}>
      {NavlinkList.map((navItem) => (
        <MobileNavItem key={navItem.id} {...navItem} />
      ))}
    </Stack>
    </Collapse>
  </Box>
  <Flex alignItems={'cemter'} justifyContent={'center'} display={{ base: 'none', md: 'flex' }} ml={10}>
    <DesktopNav NavlinkList={NavlinkList}/>
  </Flex>
  </Box>
  )
}

const DesktopNav = ({NavlinkList}) => {
    const linkColor = useColorModeValue('gray.600', 'gray.200')
    const linkHoverColor = useColorModeValue('gray.800', 'white')
    const popoverContentBgColor = useColorModeValue('white', 'gray.800')
  
    return (
      <Flex gap={'15px'} direction={'row'} alignItems={'center'} justifyContent={'center'} spacing={4}>
        {NavlinkList.map((navItem) => (
          <Box key={navItem.label}>
            <Link to={navItem.to}
            fontSize={'lg'}
            fontWeight={500}
            p={2}
            color={linkColor}
            _hover={{
              textDecoration: 'none',
              color: linkHoverColor,
            }}>
              {navItem.label}
              </Link>
          </Box>
        ))}
      </Flex>
    )
  }

const MobileNavItem = ({ label, children, to }) => {
    const { isOpen, onToggle } = useDisclosure()
  
    return (
      <Stack spacing={4} onClick={children && onToggle}>
        
        <Link
          py={2}
          to={to}
          justifyContent="space-between"
          alignItems="center"
          fontWeight={600} color={useColorModeValue('gray.600', 'gray.200')}
          _hover={{
            textDecoration: 'none',
          }}>
            {label}
        </Link>
      </Stack>
    )
  }
  
export default Navbar