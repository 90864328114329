import React from 'react'
import {
  Container,
  Stack,
  Flex,
  Box,
  Heading,
  Text,
  Button,
  Image,
  Icon,
  IconButton,
  createIcon,
  IconProps,
  useColorModeValue,
} from '@chakra-ui/react'
import HeroImage from '../../Assets/images/home.webp'
import {Link }from 'react-router-dom'
import Partners from '../Partners'

const Home = () => {
  return (
    <Box>
    <Box>
      <Stack
      spacing={{ base: 4, sm: 6 }} 
      backgroundColor={'#C2A9AF'}
        align={'center'}
        justifyContent={'flex-start'}
        direction={{ base: 'column', md: 'row' }}>
        <Stack p={'30px'} flex={2} textAlign={'start'} justifyContent={'flex-start'} alignItems={'flex-start'} spacing={{ base: 5, md: 10 }}>
        <Text
            textTransform={'uppercase'}
            color={'blue.400'}
            fontWeight={600}
            fontSize={'sm'}
            bg={useColorModeValue('blue.50', 'blue.900')}
            p={2}
            alignSelf={'flex-start'}
            rounded={'md'}
            >
            Welcome
          </Text>

          <Heading
            lineHeight={1.1}
            fontWeight={600}
            fontSize={{ base: '3xl', sm: '3xl', lg: '5xl' }}>
            <Text
              as={'span'}
              color={'#000'}
              position={'relative'}
              fontWeight={900}
              _after={{
                content: "''",
                width: 'full',
                height: '30%',
                position: 'absolute',
                bottom: 1,
                left: 0,
                bg: 'red.400',
                zIndex: -1,
              }}>
              CODISE-IT,
              <br/>
              <span fontSize={{ base: '2xl', sm: '2xl', lg: '3xl' }}>Your Trusted IT Service Provider</span>
            </Text>
          </Heading>
          <Text color={'#000'}>
          Welcome to CODISE-IT, the leading provider of Managed IT Services for businesses of all sizes. With our comprehensive range.
          </Text>
          <Stack spacing={{ base: 4, sm: 6 }} direction={{ base: 'column', sm: 'row' }}>
            
            <Link to="/services"><Button
              size={'lg'}
              fontWeight={'normal'}
              px={6}
              colorScheme={'red'}
              bg={'#333'}
              color={'#fff'}
              _hover={{ bg: 'red.500' }}>
              View More
            </Button>
            </Link>
          </Stack>
        </Stack>
        <Flex
          flex={4}
          justify={'center'}
          align={'center'}
          position={'relative'}
          w={'full'}
          h={'full'}>
          <Box
            position={'relative'}
            boxShadow={'2xl'}
            width={'full'}
            height={'full'}
            overflow={'hidden'}>
            <Image
              alt={'Hero Image'}
              fit={'cover'}
              align={'center'}
              w={'100%'}
              h={'100%'}
              src={HeroImage}
            />
          </Box>
        </Flex>
      </Stack>
    </Box>
    <Partners />
    </Box>
  )
}

export default Home