import React from 'react'
import { Stack, Text, Button,Heading,Image } from '@chakra-ui/react'
import { FcLock } from 'react-icons/fc'
import BackImage from "../../Assets/images/contact.webp"
import { useColorMode, useColorModeValue, IconButton } from '@chakra-ui/react';
import WImage from "../../Assets/images/whatapp.png"
import { Link } from 'react-router-dom'
const Contact = () => {
const value = useColorModeValue('white', 'black');
  return (
    <Stack p="4" boxShadow="lg" m="4" borderRadius="sm" backgroundImage={BackImage}>
      <Stack justifyContent={'center'} alignItems={'center'} m={{base:'0px',md:'70px'}} p={{base:'10px',md:'70px'}} pt={{base:'0px',md:'20px'}}  minH={'70vh'} backgroundColor={value}>
      <Text fontWeight={700} textAlign={'center'} fontSize={'3xl'}>Contact Us</Text>
      <hr/>
        <Text fontSize={{ base: 'lg' }} textAlign={'center'} mb={'20px'}>
        Let us tailor a service package that meets your needs. Tell us a little about your business, and we will get back to you with some ideas as soon as possible.  
        </Text>
          <Heading as={'h4'}>CODISE-IT</Heading>
          <Text>7927 Foothill Boulevard, Sunland, California 91040, United States</Text>
          <br/>
          <Text>info@codise-it.com</Text>
          <Button href="https://wa.me/15629779228?text=I'm%20inquiring%20about%20the%20It%20Service" as="a" height={'60px'} width={'50%'}><Image src={WImage} height={'40px'} alt="whatapp icon"/>Message Us on WhatsApp</Button>
          <Text fontSize={'3xl'} mt={'20px'} fontWeight={600}>Hours</Text>
          <Text>Monday - Friday: 9am - 5pm<br/>
            Saturday - Sunday: Remote Support Only</Text>
      </Stack>
    </Stack>
  )
}

export default Contact