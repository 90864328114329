import { Heading,Flex } from '@chakra-ui/react'
import React from 'react'

const Blog = () => {
  return (
    <Flex height={'50vh'} justifyContent={'center'} alignItems={'center'}>
    <Heading>Coming Soon...</Heading>
    </Flex>
  )
}

export default Blog