import React from 'react';
import {
  ChakraProvider,
  Container,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  theme,
} from '@chakra-ui/react';
import Navbar from './Components/Navbar' 
import Footer from './Components/Footer';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  HashRouter
} from "react-router-dom";
import Home from "./Pages/Home"
import Services from "./Pages/Services"
import Service from "./Pages/Services/Sevice"
import About from "./Pages/About"
import Blog from "./Pages/Blog"
import Contact from "./Pages/Contact"

import { ColorModeSwitcher } from './Components/Theme/ColorModeSwitcher';
function App() {
  return (
    <ChakraProvider theme={theme}>
      <Router>
      <Box textAlign="center" fontSize="xl">
        <Navbar/>
        <Box mt={'30px'} mb={'30px'} minH={"70vh"}>
        <Routes>
      <Route exact path="/" element={ <Home/> } />
      <Route path="/about" element={ <About/> } />
      <Route path="/services" element={ <Services/> } />
      {/* Nested Routes */}
      <Route path="/services/:id" element={ <Service/> } />
      <Route path="/blog" element={ <Blog/> } />
      <Route path="/contact" element={ <Contact/> } />
    </Routes>
        </Box>
        <Footer/>
      </Box>
      </Router>
    </ChakraProvider>
  );
}

export default App;
